import React from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CertificationsContent from '../components/Certifications/Certifications'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { CERTIFICATIONS as CERTIFICATIONS_LINK } from '../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import defaultFaqs from '../components/FAQs/faqs-list'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Certifications',
    url: CERTIFICATIONS_LINK,
  },
]

const Certifications: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Certifications: Read About RNG & AIGF Membership Certificate | Mega"
        description="Read about the certifications of Mega. Mega hold a RNG certificates and AIGF member. Click to know why Mega the safest and most fair platform to play!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(defaultFaqs)}
      />
      <CertificationsContent />
    </Layout>
  )
}

export default Certifications
